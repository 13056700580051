import { CabinetDTO } from '../../types/serverInterface/cabinetDTO';
import { createSlice } from '@reduxjs/toolkit';
import {
  editFileLinksUserThunk,
  editUserThunk,
  getCabinetThunk,
  getUserDocumentLinksThunk,
} from './thunk';
import { errorHandler, NotificationType } from '../handlers';
import { DocumentLinkDTO } from '../../types/serverInterface/documentLink';

export type UserState = {
  cabinet: CabinetDTO | null;
  documentList: DocumentLinkDTO[];
  isLoading: boolean;
  notifications: NotificationType[];
};

const initialState: UserState = {
  cabinet: null,
  documentList: [],
  isLoading: false,
  notifications: [],
};

/**
 * Добавление уведомления
 *
 * @param state состояние
 * @param notification новое уведомление
 */
const addNotification = (state: UserState) => (notification: NotificationType) => {
  const arr = [...state.notifications];
  arr.push(notification);

  state.notifications = arr;
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // getCabinetThunk
    builder.addCase(getCabinetThunk.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getCabinetThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.cabinet = action.payload;
    });

    builder.addCase(getCabinetThunk.rejected, (state, action) => {
      state.isLoading = false;
      errorHandler(action)(addNotification(state));
    });

    // editUserThunk
    builder.addCase(editUserThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // getUserDocumentLinks
    builder.addCase(getUserDocumentLinksThunk.fulfilled, (state, action) => {
      state.documentList = action.payload;
    });

    builder.addCase(getUserDocumentLinksThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });

    // editFileLinksUser
    builder.addCase(editFileLinksUserThunk.rejected, (state, action) => {
      errorHandler(action)(addNotification(state));
    });
  },
});

export const userReducer = userSlice.reducer;
