import { FC, useState } from 'react';
import { useAppDispatch } from '../../../../app/hooks/store';
import {
  customFormValidation,
  ErrorsCustomFormList,
} from '../../../../components/CustomForm/customFormValidation';
import {
  createCellPurposeAction,
  editCellPurposeAction,
} from '../../../../state/productBase/actions';
import { CustomFormType } from '../../../../types/serverInterface/customForm';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import DefaultModal from '../../../../components/DefaultModal';
import { TextField } from '@consta/uikit/TextField';
import EditCustomForm from '../../../../components/CustomForm';
import { Switch } from '@consta/uikit/Switch';
import { useTranslation } from 'react-i18next';
import {
  CreateCellPurposeDTO,
  EditCellPurposeDTO,
} from '../../../../types/serverInterface/cellPurpose';

/**
 * Дефолтное значения формы создания назначения контейнера
 */
const createInitialValue: CreateCellPurposeDTO = {
  name: '',
  view: false,
  tasted: false,
  configMachine: [],
};

/**
 * Дополнительные свойства формы создания назначения контейнера
 */
type CreateCellPurposeFormProps = {
  /**
   * Тип формы
   */
  type: 'create';
};

/**
 * Дополнительные свойства формы изменения назначения контейнера
 */
type EditCellPurposeFormProps = {
  /**
   * Тип формы
   */
  type: 'edit';
  /**
   * Назанчение контейнера
   */
  cellPurpose: EditCellPurposeDTO;
  /**
   * id назначение контейнера
   */
  cellPurposeId: number;
};

/**
 * Свойства компонента CellPurposeForm
 */
type CellPurposeFormProps = (CreateCellPurposeFormProps | EditCellPurposeFormProps) & {
  /**
   * Флаг открытия формы назначения контейнера
   */
  isOpen: boolean;
  /**
   * Обработчик закрытия
   */
  onClose: () => void;
};

/**
 * Форма назначения контейнера
 */
const CellPurposeForm: FC<CellPurposeFormProps> = (props) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const { isOpen, type, onClose } = props;

  const isCreate = type === 'create';

  const initialValue = isCreate ? createInitialValue : props.cellPurpose;

  const [formValue, setFormValue] = useState(initialValue);
  const [formError, setFormError] = useState<ErrorsCustomFormList | null>(null);

  // Обработчики
  function handleSubmit() {
    const isError = handleValidate();

    if (!isError) {
      if (isCreate) {
        dispatch(createCellPurposeAction(formValue)).then(() => {
          onClose();
          setFormValue(createInitialValue);
        });
      }

      if (!isCreate) {
        dispatch(editCellPurposeAction(formValue, props.cellPurposeId)).then(() => {
          onClose();
          setFormValue(createInitialValue);
        });
      }
    }
  }

  const handleChange =
    (key: keyof CreateCellPurposeDTO) =>
    ({ value }: { value: string | null }) => {
      setFormValue((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleSwitchChange =
    (key: keyof CreateCellPurposeDTO) =>
    ({ checked }: { checked: boolean }) => {
      setFormValue((prevState) => ({ ...prevState, [key]: checked }));
    };

  // TODO: дублирование кода
  const handleConfigChange = (data: CustomFormType) => (errors: ErrorsCustomFormList | null) => {
    setFormValue((prevState) => ({ ...prevState, configMachine: data }));
    setFormError(errors);
  };

  const handleValidate = (): boolean => {
    const { isError, data } = customFormValidation(formValue.configMachine);

    setFormError(data);

    return isError;
  };

  const handleCancelButtonClick = () => {
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  //render методы
  const renderActions = () => (
    <>
      <Button
        label={t('productBaseConfig.cellPurpose.form.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button
        label={t('productBaseConfig.cellPurpose.form.validate.button.label')}
        view="secondary"
        onClick={(e) => {
          e.preventDefault();
          handleValidate();
        }}
      />
      <Button
        label={
          isCreate
            ? t('productBaseConfig.cellPurpose.form.add.button.label')
            : t('productBaseConfig.cellPurpose.form.edit.button.label')
        }
        onClick={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      />
    </>
  );

  return (
    <DefaultModal
      modalTitle={
        isCreate
          ? t('productBaseConfig.cellPurpose.form.create.modal.title')
          : t('productBaseConfig.cellPurpose.form.edit.modal.title')
      }
      isOpen={isOpen}
      onClose={handleClose}
      renderActions={renderActions}
    >
      <TextField
        label={t('productBaseConfig.cellPurpose.form.name.input.label')}
        name="name"
        width="full"
        value={formValue.name}
        onChange={handleChange('name')}
      />
      <Switch
        label={t('productBaseConfig.cellPurpose.form.tasted.switch.label')}
        checked={formValue.tasted}
        onChange={handleSwitchChange('tasted')}
      />
      <Switch
        label={t('productBaseConfig.cellPurpose.form.sportPit.switch.label')}
        checked={formValue.view}
        onChange={handleSwitchChange('view')}
      />
      <EditCustomForm
        config={formValue.configMachine}
        errors={formError}
        onChange={handleConfigChange}
      />
    </DefaultModal>
  );
};

export default CellPurposeForm;
