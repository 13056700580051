import { FC, useEffect, useState } from 'react';
import { MachineStorageProductCellType } from '../../../../../../components/Machine/MachineStorage/types';
import styles from '../MachineFillingEdit.module.scss';
import { Combobox } from '@consta/uikit/Combobox';
import { DefaultComboboxItem } from '../../../../../../types/types';
import {
  getBrandListAction,
  getProductLineListAction,
  getProductListAction,
} from '../../../../../../state/productBase/actions';
import { useAppDispatch, useAppSelector } from '../../../../../../app/hooks/store';
import { selectOrganizationId } from '../../../../../../state/organization/selectors';
import { MachineStorageInfoProductCellFormatted } from '../../../../../../types/serverInterface/machineDTO';
import classNames from 'classnames';
import { getViewStorageItemClassName } from '../../../../../../components/Machine/MachineStorage/helpers';
import { ProductGroup } from '../../../../../../types/serverInterface/storageDTO';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { IconSetting } from '../../../../../../assets/icon/iconSetting';
import { StorageFormCardError } from '../MachineFillingEdit';
import MachineFillingEditListItemSettings from './MachineFillingEditListItemSettings';
import { sortByName } from '../../../../../../helpers/sortByName';

/**
 * Свойства компонента MachineFillingEditListItem
 */
type MachineFillingEditListItemProps = {
  /**
   * Ошибки элемента формы
   */
  error: StorageFormCardError<MachineStorageInfoProductCellFormatted>;
  /**
   * Ячейка
   */
  cell: MachineStorageProductCellType;
  /**
   * Обработчик изменений текстового поля
   *
   * @param key ключ поля
   * @param value значение
   */
  onInputChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ value }: { value: string | null }) => void;
  /**
   * Обработчик изменений выпадающего списка
   *
   * @param key ключ поля
   * @param id выбранный элемент списка
   */
  onSelectChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ value }: { value: DefaultComboboxItem | null }) => void;
  /**
   * Обработчик изменений boolean поля
   *
   * @param key ключ поля
   * @param checked значение
   */
  onBooleanChange: (
    key: keyof MachineStorageInfoProductCellFormatted,
  ) => ({ checked }: { checked: boolean }) => void;
};

/**
 * Элемент формы изменения наполнения автомата
 */
const MachineFillingEditListItem: FC<MachineFillingEditListItemProps> = ({
  cell,
  error,
  onSelectChange,
  onInputChange,
  onBooleanChange,
}) => {
  const dispatch = useAppDispatch();

  const organizationId = useAppSelector(selectOrganizationId());

  const { info } = cell;
  const {
    cellCategoryId,
    ingredientId,
    ingredientLineId,
    brandId,
    cellPurposeId,
    view,
    cellNumber,
    brandName,
    ingredientLineName,
    ingredientName,
    isActive,
    maxVolume,
    minVolume,
  } = info;

  const [isOpenCellSettings, setIsOpenCellSettings] = useState(false);
  const [brandList, setBrandList] = useState<DefaultComboboxItem[]>([]);
  const [productLineList, setProductLineList] = useState<DefaultComboboxItem[]>([]);
  const [productList, setProductList] = useState<DefaultComboboxItem[]>([]);
  const [isOpenBrandList, setIsOpenBrandList] = useState(false);
  const [isOpenIngredientLineList, setIsOpenIngredientLineList] = useState(false);
  const [isOpenIngredientList, setIsOpenIngredientList] = useState(false);
  const brand = brandId ? { name: brandName, id: brandId } : null;
  const productLine = ingredientLineId ? { name: ingredientLineName, id: ingredientLineId } : null;
  const product = ingredientId ? { name: ingredientName, id: ingredientId } : null;

  // Логика поэтапного получения списков базы товаров
  useEffect(() => {
    isOpenBrandList &&
      dispatch(
        getBrandListAction({
          organizationId,
          cellCategoryId,
          cellPurposeId,
        }),
      ).then((res) => {
        setBrandList(sortByName(res.payload as DefaultComboboxItem[]));
      });
  }, [dispatch, organizationId, cellPurposeId, cellCategoryId, isOpenBrandList]);

  useEffect(() => {
    isOpenIngredientLineList &&
      brandId &&
      dispatch(
        getProductLineListAction(brandId, {
          organizationId,
          cellCategoryId,
          cellPurposeId,
        }),
      ).then((res) => {
        setProductLineList(sortByName(res.payload as DefaultComboboxItem[]));
      });
  }, [dispatch, brandId, organizationId, cellPurposeId, cellCategoryId, isOpenIngredientLineList]);

  useEffect(() => {
    isOpenIngredientList &&
      brandId &&
      ingredientLineId &&
      dispatch(
        getProductListAction(ingredientLineId, {
          organizationId,
          cellCategoryId,
          cellPurposeId,
        }),
      ).then((res) => {
        setProductList(sortByName(res.payload as DefaultComboboxItem[]));
      });
  }, [
    dispatch,
    brandId,
    ingredientLineId,
    organizationId,
    cellPurposeId,
    cellCategoryId,
    isOpenIngredientList,
  ]);

  const isProductLineListDisabled = !brandId;
  const isProductListDisabled = !ingredientLineId;

  // Вспомогательные методы
  const getItemKey = ({ id }: { id: number }) => id;

  const getItemLabel = ({ name }: { name: string }) => name;

  // Обработчики
  const handleSelectChange = onSelectChange;

  const handleInputChange = onInputChange;

  const handleBooleanChange = onBooleanChange;

  const handleBrandChange = ({ value }: { value: DefaultComboboxItem | null }) => {
    onSelectChange('brandId')({ value });
    onSelectChange('ingredientLineId')({ value: null });
    onSelectChange('ingredientId')({ value: null });
    onInputChange('brandName')({
      value: value?.name || '',
    });
  };

  const handleProductLineChange = ({ value }: { value: DefaultComboboxItem | null }) => {
    onSelectChange('ingredientLineId')({ value });
    onSelectChange('ingredientId')({ value: null });
    onInputChange('ingredientLineName')({
      value: value?.name || '',
    });
  };

  const handleProductChange = ({ value }: { value: DefaultComboboxItem | null }) => {
    handleSelectChange('ingredientId')({ value });
    onInputChange('ingredientName')({
      value: value?.name || '',
    });
  };

  const handleOpenCellSettings = () => {
    setIsOpenCellSettings(true);
  };

  const handleCloseCellSettings = () => {
    setIsOpenCellSettings(false);
  };

  return (
    <div className={styles.MachineFillingEditListItem}>
      <div className={styles.numberCell}>
        <div
          key={cellNumber}
          className={classNames(
            styles.cellNumberCard,
            getViewStorageItemClassName(ProductGroup.POWDER, view),
          )}
        >
          <Text weight="semibold" className={styles.text}>
            {cellNumber}
          </Text>
        </div>
      </div>
      <Combobox
        items={brandList}
        value={brand}
        status={error?.brandId?.status}
        getItemLabel={getItemLabel}
        getItemKey={getItemKey}
        onChange={handleBrandChange}
        onClick={() => setIsOpenBrandList(true)}
      />
      <Combobox
        disabled={isProductLineListDisabled}
        items={productLineList}
        value={productLine}
        status={error?.ingredientLineId?.status}
        getItemLabel={getItemLabel}
        getItemKey={getItemKey}
        onChange={handleProductLineChange}
        onClick={() => setIsOpenIngredientLineList(true)}
      />
      <Combobox
        disabled={isProductListDisabled}
        items={productList}
        value={product}
        status={error?.ingredientId?.status}
        getItemLabel={getItemLabel}
        getItemKey={getItemKey}
        onChange={handleProductChange}
        onClick={() => setIsOpenIngredientList(true)}
      />
      <Button
        onlyIcon
        iconLeft={IconSetting as any}
        view="clear"
        onClick={handleOpenCellSettings}
      />
      <MachineFillingEditListItemSettings
        isOpen={isOpenCellSettings}
        isActive={isActive}
        cellNumber={cellNumber}
        maxVolume={maxVolume}
        minVolume={minVolume}
        onCloseCellSettings={handleCloseCellSettings}
        onInputChange={handleInputChange}
        onBooleanChange={handleBooleanChange}
      />
    </div>
  );
};

export default MachineFillingEditListItem;
