import { FC, useEffect } from 'react';
import styles from './MachineHomePage.module.scss';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks/store';
import {
  getMachineHomePageInfoAction,
  getMachineLogListAction,
} from '../../../../state/machineControl/actions';
import {
  selectMachineBaseInfo,
  selectMachineHomePageInfo,
  selectMachineLogList,
} from '../../../../state/machineControl/selectors';
import MachineHomaPageStatus from './MachineHomaPageStatus';
import MachineHomaPageSales from './MachineHomaPageSales';
import MachineHomaPageLogs from './MachineHomaPageLogs';
import MachineHomaPageHistory from './MachineHomaPageHistory';
import MachineHomaPageScheme from './MachineHomaPageScheme';
import HorizontalContainer from '../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../components/VerticalContainer';
import { DisplayType, displayTypeValue } from '../../../App';
import classNames from 'classnames';

/**
 * Свойства компонента MachineHomePage
 */
type MachineHomePageProps = {
  machineId: number;
};

/**
 * Домашняя страница автомата
 */
const MachineHomePage: FC<MachineHomePageProps> = ({ machineId }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  const dispatch = useAppDispatch();

  const { state: machine } = useAppSelector(selectMachineHomePageInfo());
  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());
  const { state: logMachineList } = useAppSelector(selectMachineLogList());

  useEffect(() => {
    if (machineId) {
      dispatch(getMachineHomePageInfoAction(machineId));
      dispatch(getMachineLogListAction(machineId));
    }
  }, [dispatch, machineId]);

  if (!machine || !machineBaseInfo) return null;

  return (
    <HorizontalContainer
      space={isLaptop ? '3xl' : '5xl'}
      className={classNames(styles.MachineHomePage, isLaptop && styles.laptop)}
    >
      <VerticalContainer space={isLaptop ? '3xl' : '5xl'} className={styles.leftSide}>
        <VerticalContainer space="l">
          <MachineHomaPageStatus status={machineBaseInfo.status} />
          <MachineHomaPageSales serialNumber={machineBaseInfo.machine.serialNumber} />
        </VerticalContainer>
        <MachineHomaPageLogs logs={logMachineList?.logs || []} />
      </VerticalContainer>
      <VerticalContainer space={isLaptop ? '3xl' : '5xl'} className={styles.rightSide}>
        <MachineHomaPageScheme storage={machine} />
        <MachineHomaPageHistory histories={machine.histories} />
      </VerticalContainer>
    </HorizontalContainer>
  );
};

export default MachineHomePage;
