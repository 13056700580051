import { FC, useState } from 'react';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import ContentCard from '../../../../../components/ContentCard';
import React from 'react';
import MachineSettingsGroupAction from './MachineSettingsGroupAction';
import styles from './MachineSettingsActions.module.scss';
import HorizontalContainer from '../../../../../components/HorizontalContainer';
import VerticalContainer from '../../../../../components/VerticalContainer';
import { ActionGroup, ConnectionStatus } from '../../../../../types/serverInterface/machineDTO';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import { sendMachineCustomActionAction } from '../../../../../state/machineControl/actions';
import { selectMachineBaseInfo } from '../../../../../state/machineControl/selectors';
import MachineOfflineBlockModal from '../../MachineOfflineBlockModal';
import { MachineSettingsActionsProps } from './MachineSettingsGroupAction/types';

// TODO: посмотреть в рамках задачи
//  10579 Завести картинку для milk автомата
//  https://sheykertekh.bitrix24.ru/workgroups/group/85/tasks/task/view/10579/
/**
 * Получение логотипа автомата в зависимости от названия модели
 *
 * @param modelName название модели
 */
const getMachineLogoFileName = (modelName: string) => {
  switch (modelName) {
    case 'ShakerTouch':
      return '/img/ShakerTouch.png';
    default:
      return '/img/milk.png';
  }
};

/**
 * Кастомные действия автомата
 */
const MachineSettingsActions: FC<MachineSettingsActionsProps> = ({
  machine,
  actions,
  machineId,
}) => {
  const dispatch = useAppDispatch();

  const { state: machineBaseInfo } = useAppSelector(selectMachineBaseInfo());

  const { serialNumber, modelName } = machine;

  const [isActionCodeOfflineBlock, setIsActionCodeOfflineBlock] = useState<string | null>(null);

  const logoImagePath = process.env.PUBLIC_URL + getMachineLogoFileName(modelName);

  // Обработчики
  const handleActionClick = (code: string) => {
    if (machineBaseInfo && machineBaseInfo.connectionStatus === ConnectionStatus.OFFLINE) {
      setIsActionCodeOfflineBlock(code);
      return null;
    }

    dispatch(sendMachineCustomActionAction({ machineId, code }));
  };

  const handleEditOfflineBlockModalClose = () => {
    setIsActionCodeOfflineBlock(null);
  };

  const handleEditOfflineBlockModalRepeatClick = () => {
    isActionCodeOfflineBlock && handleActionClick(isActionCodeOfflineBlock);
  };

  // render методы
  const renderCustomActionGroup = (group: ActionGroup, index: number) => {
    return (
      <MachineSettingsGroupAction key={index} group={group} onActionClick={handleActionClick} />
    );
  };

  const renderCustomActions = () => (
    <HorizontalContainer space="2xs">{actions?.map(renderCustomActionGroup)}</HorizontalContainer>
  );

  return (
    <ContentCard className={styles.MachineSettingsActions}>
      <MachineOfflineBlockModal
        isOpen={!!isActionCodeOfflineBlock}
        onCancel={handleEditOfflineBlockModalClose}
        onRepeatClick={handleEditOfflineBlockModalRepeatClick}
      />
      <HorizontalContainer space="2xl">
        <div className={styles.machineImgWrapper}>
          <img src={logoImagePath} alt="machine-logo" />
        </div>
        <VerticalContainer space="2xs" justify="center">
          <Text size="l" weight="semibold">
            {modelName} {serialNumber}
          </Text>
          {/*<Text size="m" weight="regular">*/}
          {/*  Статус: Работает*/}
          {/*</Text>*/}
          {/*<Checkbox checked={false} label="Уведомлять об изменении статуса" />*/}
        </VerticalContainer>
      </HorizontalContainer>
      {renderCustomActions()}
    </ContentCard>
  );
};

export default MachineSettingsActions;
