/**
 * Сортировка массива объектов по полю name
 *
 * @param arr исходный массив с полем name
 */
export const sortByName = <T extends { name: string }>(arr: T[]): T[] => {
  return [...arr].sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });
};
