import { AbstractApiModule } from '../../abstractApiModule';
import {
  CreatePromoCodeGroupReq,
  CreatePromoCodeGroupRes,
  CreatePromoCodeReq,
  CreatePromoCodeRes,
  EditPromoCodeReq,
  GeneratePromoCodeRes,
  PromoCodeGroupRes,
  PromoCodeListFiltersReq,
  PromoCodeListFiltersRes,
  PromoCodeListRes,
  PromoCodeRes,
} from '../../../../types/serverInterface/promoCodeDTO';
import { MachineSalesRes } from '../../../../types/serverInterface/machineDTO';
import { Pagination, PaginationQtyRes } from '../../../../types/types';
import { salesBaseUrl } from '../../../../consts';
import {
  MachineSaleInfoLastDateDTO,
  MachineSaleInfoQtyDTO,
} from '../../../../types/serverInterface/MachineSaleInfoDTO';

// TODO: вынести в глобальный метод
const getDataFromServer = async <T>(data: T): Promise<T> => {
  return await new Promise((resolve) => {
    setTimeout(() => resolve(data), 100);
  });
};

function objectToQueryString(
  obj1: Record<string, number | string | boolean | null>,
  obj2?: Record<string, number | string | boolean | null>,
): string {
  const queryString1 = Object.entries(obj1)
    .filter(([key, value]) => value !== null)
    .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
    .join('&');

  const queryString2 =
    obj2 &&
    Object.entries(obj2)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => `${key}=${encodeURIComponent(String(value))}`)
      .join('&');

  return `?${queryString2}&${queryString1}`;
}

export class PromoCodeModule extends AbstractApiModule {
  /**
   * Создание промокода
   *
   * @param data данные формы создания промокода
   */
  createPromoCodeGroup(data: CreatePromoCodeGroupReq) {
    return this.request.post<CreatePromoCodeGroupReq, CreatePromoCodeGroupRes>(
      `${salesBaseUrl}/promo-group/create`,
      data,
    );
  }

  /**
   * Получение списка групп промокодов организации
   *
   * @param organizationId id организации
   */
  getPromoCodeGroupList(organizationId: number) {
    return this.request.get<void, PromoCodeGroupRes[]>(
      `${salesBaseUrl}/promo-group/list/${organizationId}`,
    );
  }

  /**
   * Генерация промокода
   *
   * @param organizationId id организации
   */
  generatePromoCode(organizationId: number) {
    return this.request.post<{ prefix: string }, GeneratePromoCodeRes>(
      `${salesBaseUrl}/promo-generation/single/${organizationId}`,
      { prefix: '' },
    );
  }

  /**
   * Создания промокода
   *
   * @param data данные формы создания промокода
   */
  createPromoCode(data: CreatePromoCodeReq) {
    return this.request.post<CreatePromoCodeReq, CreatePromoCodeRes>(
      `${salesBaseUrl}/promo-code/create`,
      data,
    );
  }

  /**
   * Изменение промокода
   *
   * @param data данные формы изменения промокода
   * @param promoCodeId  id промокода
   */
  editPromoCode(data: EditPromoCodeReq, promoCodeId: number) {
    return this.request.post<EditPromoCodeReq>(
      `${salesBaseUrl}/promo-code/edit/${promoCodeId}`,
      data,
    );
    // return getDataFromServer(null);
  }

  /**
   * Получение информации о промокоде
   *
   * @param promoCodeId id промокода
   */
  getPromoCode(promoCodeId: number) {
    return this.request.get<number, PromoCodeRes>(
      `${salesBaseUrl}/promo-code/element/${promoCodeId}`,
    );
  }

  /**
   * Получение списка промокодов
   *
   * @param organizationId id организации
   * @param filters фильтры
   * @param pagination страницы
   */
  getPromoCodeList(
    organizationId: number,
    filters: PromoCodeListFiltersReq,
    pagination: Pagination,
  ) {
    return this.request.get<number, PromoCodeListRes>(
      `${salesBaseUrl}/promo-code/list/${organizationId}${objectToQueryString(filters, pagination)}`,
    );
  }

  /**
   * Переключение состояние избранности промокода
   *
   * @param promoCodeId id промокода
   * @param isSelected флаг выбранного промокода
   */
  switchSelectPromoCode(promoCodeId: number, isSelected: boolean) {
    return this.request.post<{ isSelected: boolean }>(
      `${salesBaseUrl}/promo-code/switch-selected/${promoCodeId}`,
      { isSelected },
    );
  }

  /**
   * Получение пограничных значений для фильтров списка промокодов
   *
   * @param organizationId id организации
   * @param filters Текущие фильтры
   */
  getFilterPromoCode(organizationId: number, filters: PromoCodeListFiltersReq) {
    return this.request.get<number, PromoCodeListFiltersRes>(
      `${salesBaseUrl}/promo-code/filter/${organizationId}${objectToQueryString(filters)}`,
    );
  }

  /**
   * Список продаж автомата
   *
   * @param serialNumber серийный номер автомата
   * @param page страница
   * @param limit элементов наа странице
   */
  getMachineSaLesList(serialNumber: string, page: number, limit: number) {
    return this.request.get<number, MachineSalesRes>(
      `${salesBaseUrl}/sale/list/${serialNumber}?page=${page}&limit=${limit}`,
    );
  }

  /**
   * Количество элементов продаж
   *
   * @param serialNumber серийный номер автомата
   */
  getMachineSaLesListQty(serialNumber: string) {
    return this.request.get<number, PaginationQtyRes>(`${salesBaseUrl}/sale/qty/${serialNumber}`);
  }

  /**
   * Получение даты последней продажи автомата
   *
   * @param serialNumber серийный номер автомата
   */
  getLastSaleDateByMachineSerialNumber(serialNumber: string) {
    return this.request.get<number, MachineSaleInfoLastDateDTO>(
      `${salesBaseUrl}/sale/date-last-sale/${serialNumber}`,
    );
  }

  /**
   * Получение количества продаж за различные периоды
   *
   * @param serialNumber серийный номер
   */
  getSalesQtyByMachineSerialNumber(serialNumber: string) {
    return this.request.get<number, MachineSaleInfoQtyDTO>(
      `${salesBaseUrl}/sale-period/all/${serialNumber}`,
    );
  }
}
