import { FC } from 'react';
import ContentCard from '../../../../../components/ContentCard';
import { Text } from '@consta/uikit/__internal__/src/components/Text';
import styles from './MachineHomaPageLogs.module.scss';
import { LogMachines, LogMachinesTypeEnum } from '../../../../../types/serverInterface/machineDTO';
import { DisplayType, displayTypeValue } from '../../../../App';
import classNames from 'classnames';
import MachineHomePageLog from './MachineHomePageLog';

const mockLogs: LogMachines[] = [
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.SUCCESS,
    description: 'Пополнения остатков',
    name: 'Автомат обслужен',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.WARNING,
    description: 'Заменить бутылку с водой',
    name: 'Требует обслуживания',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.ALERT,
    description: 'Error.SnackDoesntAnswer',
    name: 'Ошибка',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.SUCCESS,
    description: 'Пополнения остатков',
    name: 'Автомат обслужен',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.WARNING,
    description: 'Заменить бутылку с водой',
    name: 'Требует обслуживания',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.ALERT,
    description: 'Error.SnackDoesntAnswer',
    name: 'Ошибка',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.SUCCESS,
    description: 'Пополнения остатков',
    name: 'Автомат обслужен',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.WARNING,
    description: 'Заменить бутылку с водой',
    name: 'Требует обслуживания',
  },
  {
    date: '09.04.2024 11:46',
    type: LogMachinesTypeEnum.ALERT,
    description: 'Error.SnackDoesntAnswer',
    name: 'Ошибка',
  },
];

/**
 * Свойства компонента MachineHomaPageLogs
 */
type MachineHomaPageLogsProps = {
  /**
   * Сообщения автомата
   */
  logs: LogMachines[];
};

/**
 * Сообщения автомата
 */
const MachineHomaPageLogs: FC<MachineHomaPageLogsProps> = ({ logs = mockLogs }) => {
  const isLaptop = displayTypeValue === DisplayType.LAPTOP;

  // render методы
  const renderLog = (log: LogMachines) => <MachineHomePageLog log={log} />;

  return (
    <ContentCard className={classNames(styles.MachineHomaPageLogs, isLaptop && styles.laptop)}>
      <Text size={isLaptop ? 'l' : 'xl'} weight="semibold">
        Сообщения от автомата
      </Text>
      {logs?.map((log) => renderLog(log))}
    </ContentCard>
  );
};

export default MachineHomaPageLogs;
