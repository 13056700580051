import { AbstractApiModule } from '../../abstractApiModule';
import { CabinetDTO, EditUserDTO } from '../../../../types/serverInterface/cabinetDTO';
import {
  DocumentLinkDTO,
  EditFileLinkUserDTO,
} from '../../../../types/serverInterface/documentLink';
import { organizationBaseUrl } from '../../../../consts';

export class UserModule extends AbstractApiModule {
  /**
   * Получение личного кабинета пользователя
   */
  fetchCabinet() {
    return this.request.get<void, CabinetDTO>(`${organizationBaseUrl}/cabinet`);
  }

  /**
   * Изменение пользователя
   *
   * @param data данные формы изменения пользователя
   */
  editUser(data: EditUserDTO) {
    return this.request.patch<EditUserDTO, void>(`${organizationBaseUrl}/cabinet`, data);
  }

  /**
   * Получение списка файлов пользователя
   *
   * @param userUuid uuid пользователя
   */
  fetchUserDocs(userUuid: string): Promise<DocumentLinkDTO[]> {
    return this.request.get(`${organizationBaseUrl}/file-link/user/${userUuid}`);
  }

  /**
   * Изменение списка файлов пользователя
   *
   * @param data новый список файлов пользователя
   */
  updateFileLinksUser(data: EditFileLinkUserDTO): Promise<void> {
    return this.request.post(`${organizationBaseUrl}/file-link/user`, data);
  }
}
