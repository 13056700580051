import { FC, useState } from 'react';
import {
  EditedProductComponent,
  EditProductDTO,
  ProductCharacteristicItem,
  ProductCharacteristics,
  ProductComponent,
  ProductDetailsDTO,
} from '../../../../../types/serverInterface/productDTO';
import { ProductInfoSteps } from '../../ProductInfo/ProductInfo';
import ProductBasicForm from '../ProductBasicForm';
import ProductCharacteristicsForm from '../ProductCharacteristicsForm';
import { useAppDispatch, useAppSelector } from '../../../../../app/hooks/store';
import {
  selectCellCategoryList,
  selectCellPurposeList,
  selectCellViewList,
  selectTasteList,
} from '../../../../../state/productBase/selectors';
import ProductComponentsForm from '../ProductComponentsForm';
import ProductCalibrationForm from '../ProductCalibrationForm';
import { CustomFormType } from '../../../../../types/serverInterface/customForm';
import {
  editProductAction,
  editProductCalibrationAction,
  editProductCharacteristicsAction,
  editProductComponentAction,
} from '../../../../../state/productBase/actions';
import DefaultModal from '../../../../../components/DefaultModal';
import { Button } from '@consta/uikit/__internal__/src/components/Button';
import { useTranslation } from 'react-i18next';

/**
 * Свойства компнента EditProductForm
 */
type EditProductFormProps = {
  /**
   * Продукт
   */
  product: ProductDetailsDTO;
  /**
   * Выбранный шаг
   */
  selectedStep: ProductInfoSteps;
  /**
   * Обработчик закрытия формы редактирования
   */
  onClose: () => void;
};

/**
 * Форма изменения продукта
 */
const EditProductForm: FC<EditProductFormProps> = ({ product, selectedStep, onClose }) => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const cellCategoryList = useAppSelector(selectCellCategoryList());
  const cellPurposeList = useAppSelector(selectCellPurposeList());
  const tasteList = useAppSelector(selectTasteList());
  const viewList = useAppSelector(selectCellViewList());

  const [characteristicFormState, setCharacteristicFormState] = useState<ProductCharacteristics>({
    ...product.characteristics,
  });
  const [basicFormState, setBasicFormState] = useState<EditProductDTO>({ ...product });
  const [componentsFormState, setComponentsFormState] = useState<
    (ProductComponent | EditedProductComponent)[]
  >(product.components);
  const [calibrationCategoryFormState, setCalibrationCategoryFormState] = useState<CustomFormType>(
    product.categoryConfigMachine,
  );
  const [calibrationPurposeFormState, setCalibrationPurposeFormState] = useState<CustomFormType>(
    product.purposeConfigMachine,
  );

  // Обработчики
  const handleClose = () => {
    onClose();
  };

  const handleCharacteristicsChange =
    (key: keyof ProductCharacteristics) => (value: ProductCharacteristicItem | null) => {
      setCharacteristicFormState((prevState) => ({ ...prevState, [key]: value }));
    };

  const handleBasicFormChange = (key: keyof EditProductDTO) => (value: string | null) => {
    setBasicFormState((prevState) => ({ ...prevState, [key]: value }));
  };

  const handeComponentsFormChange = (data: EditedProductComponent[]) => {
    setComponentsFormState(data);
  };

  const handleCategoryConfigChange = (data: CustomFormType) => {
    setCalibrationCategoryFormState(data);
  };

  const handlePurposeChange = (data: CustomFormType) => {
    setCalibrationPurposeFormState(data);
  };

  const handleBasicSubmit = () => {
    dispatch(editProductAction(basicFormState, product.id)).then(() => handleClose());
  };

  const handleCharacteristicSubmit = () => {
    const { cellCategory, cellPurpose, view, taste } = characteristicFormState;
    dispatch(
      editProductCharacteristicsAction(
        {
          cellCategoryId: cellCategory?.id,
          cellPurposeId: cellPurpose?.id,
          viewId: view?.id,
          tasteId: taste?.id,
        },
        product.id,
      ),
    ).then(() => handleClose());
  };

  const handleComponentsSubmit = () => {
    dispatch(editProductComponentAction(componentsFormState, product.id)).then(() => handleClose());
  };

  const handleCalibrationSubmit = () => {
    dispatch(
      editProductCalibrationAction(
        {
          categoryConfigMachine: calibrationCategoryFormState,
          purposeConfigMachine: calibrationPurposeFormState,
        },
        product.id,
      ),
    ).then(() => handleClose());
  };

  const handleSubmit = () => {
    switch (selectedStep) {
      case ProductInfoSteps.BASE:
        return handleBasicSubmit();
      case ProductInfoSteps.CHARACTERISTICS:
        return handleCharacteristicSubmit();
      case ProductInfoSteps.COMPONENTS:
        return handleComponentsSubmit();
      case ProductInfoSteps.CALIBRATION:
        return handleCalibrationSubmit();
    }
  };

  const handleCancelButtonClick = () => {
    handleClose();
  };

  // render методы
  const renderAction = () => (
    <>
      <Button
        label={t('productBase.product.form.edit.back.button.label')}
        view="clear"
        onClick={handleCancelButtonClick}
      />
      <Button label={t('productBase.product.form.edit.save.button.label')} onClick={handleSubmit} />
    </>
  );

  const renderForm = () => {
    switch (selectedStep) {
      case ProductInfoSteps.BASE:
        return <ProductBasicForm value={basicFormState} onChange={handleBasicFormChange} />;
      case ProductInfoSteps.CHARACTERISTICS:
        return (
          <ProductCharacteristicsForm
            characteristics={characteristicFormState}
            cellCategoryList={cellCategoryList}
            cellPurposeList={cellPurposeList}
            viewList={viewList}
            tasteList={tasteList}
            onChange={handleCharacteristicsChange}
          />
        );
      case ProductInfoSteps.COMPONENTS:
        return (
          <ProductComponentsForm
            productComponents={componentsFormState}
            onChange={handeComponentsFormChange}
          />
        );
      case ProductInfoSteps.CALIBRATION:
        return (
          <ProductCalibrationForm
            categoryConfig={calibrationCategoryFormState}
            purposeConfig={calibrationPurposeFormState}
            onCategoryChange={handleCategoryConfigChange}
            onPurposeChange={handlePurposeChange}
          />
        );
    }
  };
  return (
    <DefaultModal
      isOpen
      modalTitle={t('productBase.product.form.edit.modal.title')}
      onClose={onClose}
      renderActions={renderAction}
    >
      {renderForm()}
    </DefaultModal>
  );
};

export default EditProductForm;
