import { FC } from 'react';
import classNames from 'classnames';
import { getViewStorageItemClassName } from '../../../../../components/Machine/MachineStorage/helpers';
import styles from './MachineStorageCellItem.module.scss';
import {
  CupCellByList,
  ProductCellsByList,
  WaterCellByList,
} from '../../../../../types/serverInterface/machineListDTO';
import { MachineCellGroup } from '../../../../../types/serverInterface/machineDTO';

/**
 * Свойства компонента MachineStorageCellItem
 */
type MachineStorageCellItemProps = {
  cell:
    | ProductCellsByList
    | (CupCellByList & { group: MachineCellGroup; view?: undefined })
    | (WaterCellByList & { group: MachineCellGroup; view?: undefined });
};

/**
 * Ячейка склада автомата для страницы списка автомата
 */
const MachineStorageCellItem: FC<MachineStorageCellItemProps> = ({ cell }) => {
  const { maxVolume, volume } = cell;
  const volumePercent = maxVolume ? ((volume || 0) / maxVolume) * 100 : 100;

  return (
    <div
      className={classNames(
        styles.MachineStorageCellItem,
        styles.slider,
        getViewStorageItemClassName(cell.group, cell?.view),
      )}
    >
      <div className={classNames(styles.line)}>
        <div className={styles.point} />
        <div
          className={styles.lineSlider}
          style={{
            ['--slider-line-size' as string]: `${volumePercent}%`,
          }}
        />
      </div>
    </div>
  );
};

export default MachineStorageCellItem;
