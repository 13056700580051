/**
 * Функция для проверки, содержит ли переданная строка символы русской раскладки.
 *
 * Эта функция использует регулярное выражение для поиска кириллических символов
 * в строке. Если в строке присутствуют хотя бы один кириллический символ (буквы
 * русского алфавита), функция вернет true. В противном случае, она возвращает false.
 *
 * @param {string} str - Строка, которую необходимо проверить на наличие русских символов.
 *
 * @returns {boolean} - Возвращает true, если строка содержит русские символы; иначе false.
 *
 * Примеры использования:
 *
 * console.log(containsRussianCharacters("T-23070409S")); // false
 * console.log(containsRussianCharacters("Т-23070409S")); // true
 *
 * Обратите внимание, что в регулярном выражении учитываются следующие символы:
 * - строчные буквы: а, б, в, г, д, е, ё, ж, з, и, й, к, л, м, н, о, П, р, с, т, у, ф, х, ц, ч, ш, щ, ы, э, ю, я
 * - заглавные буквы: А, Б, В, Г, Д, Е, Ё, Ж, З, И, Й, К, Л, М, Н, О, П, Р, С, Т, У, Ф, Х, Ц, Ч, Ш, Щ, Ы, Э, Ю, Я
 *
 * Данная функция полезна для проверки пользовательского ввода или анализа строк,
 * чтобы убедиться, что они не содержат символов другой раскладки,
 * например, латинских букв.
 */
export const containsRussianCharacters = (str: string): boolean => {
  const russianRegex = /[а-яА-ЯЁё]/;

  return russianRegex.test(str);
};
