import { AbstractApiModule } from '../../abstractApiModule';
import {
  CreateMachineDTO,
  EditMachineBaseInfoDTO,
  EditMachineDTO,
  EditMachinePricesDTO,
  EditMachineStorageDTO,
  EditWaterCell,
  MachineBaseInfoDTO,
  MachineHomeRes,
  MachineSmallListItemDTO,
  MachinePricesInfoRes,
  MachineSettingsActionStatusDTO,
  MachineSettingsRes,
  MachineStorageInfoRes,
  RefillCellDTO,
  RefillMachineStorageDTO,
  SendMachineCustomActionDTO,
  MachineLogListDTO,
} from '../../../../types/serverInterface/machineDTO';
import {
  CreateMachineModelDTO,
  MachineModelDetailsDTO,
  MachineModelDTO,
} from '../../../../types/serverInterface/machineModelDTO';
import { machineControlBaseUrl } from '../../../../consts';
import {
  MachineRegistrationCodeDTO,
  RegenerateMachineRegistrationCodeRes,
} from '../../../../types/serverInterface/MachineRegistrationCodeDTO';
import { MachineIngredientCalibrationValueRes } from '../../../../types/serverInterface/machineIngredientCalibrationValueDTO';
import {
  MachineFiltersDTO,
  MachineListDTO,
  ShutdownReasonFilters,
} from '../../../../types/serverInterface/machineListDTO';
import {
  getMachineDisconnectParameters,
  getMachineListParameters,
  getOutletListParameters,
} from './helpers';
import {
  CreateOutletDTO,
  EditOutletDTO,
  OutletFiltersDTO,
  OutletFiltersInfoDTO,
  OutletListDTO,
  OutletMachineIdsDTO,
} from '../../../../types/serverInterface/outletDTO';

export class MachineControlModule extends AbstractApiModule {
  /**
   * Получение списка моделей автомата
   */
  fetchMachineModelList() {
    return this.request.get<void, MachineModelDTO[]>(`${machineControlBaseUrl}/machine-model/list`);
  }

  /**
   * Получение детальной информации о автомате
   *
   * @param modelId {number} id модели автомата
   */
  fetchMachineModelDetails(modelId: number) {
    return this.request.get<void, MachineModelDetailsDTO>(
      `${machineControlBaseUrl}/machine-model/element/${modelId}`,
    );
  }

  /**
   * Создание модели автомата
   *
   * @param {CreateMachineModelDTO} data данные формы создания модели автомата
   */
  createMachineModel(data: CreateMachineModelDTO) {
    return this.request.post<CreateMachineModelDTO, { id: number }>(
      `${machineControlBaseUrl}/machine-model/create`,
      data,
    );
  }

  /**
   * Удаление модели автомата
   *
   * @param {number} modelId id модели автомата
   */
  deleteMachineModel(modelId: number) {
    return this.request.delete(`${machineControlBaseUrl}/machine-model/${modelId}`);
  }

  /**
   * Удаление автомата
   *
   * @param machineId id автомата
   */
  deleteMachine(machineId: number) {
    return this.request.delete(`${machineControlBaseUrl}/machine/${machineId}`);
  }

  /**
   * Получения списка автоматов организации
   *
   * @param organizationId id организации
   */
  // fetchMachineList(organizationId: number | null) {
  //   return this.request.get<void, MachineDTO[]>(
  //     `${machineControlBaseUrl}/machine/list${organizationId ? `?organizationId=${organizationId}` : ''}`,
  //   );
  // }

  /**
   * Получение списка автоматов организации
   *
   * @param organizationId id организации
   */
  fetchMachineListByOrganizationId(organizationId: number) {
    return this.request.get<void, MachineSmallListItemDTO[]>(
      `${machineControlBaseUrl}/machine/list/${organizationId}`,
    );
  }

  // // TODO: требуется проверка
  // /**
  //  * Получение детальной информации о автомате
  //  *
  //  * @param machineId id автомата
  //  */
  // fetchMachine(machineId: number) {
  //   const machineMap: Record<number, MachineDTO> = {};
  //   return getDataFromServer(machineMap[machineId]);
  // }
  //
  // // TODO: требуется проверка
  // /**
  //  * Получение информации о складе автомата
  //  *
  //  * @param machineId id автомата
  //  */
  // fetchMachineFilling(machineId: number) {
  //   return this.request.get<void, MachineStorageDTO>(
  //     `${machineControlBaseUrl}/machine-cell/list/${machineId}`,
  //   );
  // }
  //
  // // TODO: требуется проверка
  // /**
  //  * Получение информации о товарах автомата
  //  *
  //  * @param machineId id автомата
  //  */
  // fetchMachinePrices(machineId: number) {
  //   return getDataFromServer(machinePrices[machineId]);
  // }

  // TODO: требуется проверка
  /**
   * Пополнение остатков автомата
   *
   * @param storage обновленные остатки склада автомата
   */
  refillMachine(storage: RefillCellDTO[]) {
    return this.request.post<RefillCellDTO[], void>(
      `${machineControlBaseUrl}/volume/edit`,
      storage,
    );
  }

  /**
   * Изменение автомата
   *
   * @param data данные формы изменения автомата
   * @param machineId id автомата
   */
  editMachine(data: EditMachineDTO, machineId: number) {
    return this.request.post(`${machineControlBaseUrl}/machine/edit/${machineId}`, data);
  }

  /**
   * Создание автомата
   *
   * @param data данные формы создания автомата
   */
  createMachine(data: CreateMachineDTO) {
    return this.request.post<CreateMachineDTO, { id: number }>(
      `${machineControlBaseUrl}/machine/create`,
      data,
    );
  }

  /**
   * Получение базовой информации о автомате
   *
   * @param machineId id автомата
   */
  fetchMachineBaseInfo(machineId: number) {
    return this.request.get<void, MachineBaseInfoDTO>(
      `${machineControlBaseUrl}/machine/status/${machineId}`,
    );
  }

  /**
   * Получение информации о автомате для домашней страницы
   *
   * @param machineId id автомата
   */
  fetchMachineHomePageInfo(machineId: number) {
    return this.request.get<void, MachineHomeRes>(
      `${machineControlBaseUrl}/machine-main/element/${machineId}`,
    );
  }

  /**
   * Получение списка логов автомата
   *
   * @param machineId id автомата
   */
  getMachineLogList(machineId: number) {
    return this.request.get<void, MachineLogListDTO>(
      `${machineControlBaseUrl}/machine-main/element/logs/${machineId}`,
    );
  }

  /**
   * Получение информации о складе автомата
   *
   * @param machineId id автомата
   */
  fetchMachineStorageInfo(machineId: number) {
    return this.request.get<void, MachineStorageInfoRes>(
      `${machineControlBaseUrl}/machine/element/${machineId}`,
    );
  }

  /**
   * Получение информации о ценах автомата
   *
   * @param machineId id автомата
   */
  fetchMachinePricesInfo(machineId: number) {
    return this.request.get<void, MachinePricesInfoRes>(
      `${machineControlBaseUrl}/price/list/${machineId}`,
    );
  }

  /**
   * Пополнение остатков автомата
   *
   * @param refillStorage пополненый склад
   * @param machineId id  автомата
   */
  refillMachineStorage(refillStorage: RefillMachineStorageDTO, machineId: number) {
    return this.request.post<RefillMachineStorageDTO, void>(
      `${machineControlBaseUrl}/volume/edit/${machineId}`,
      refillStorage,
    );
  }

  /**
   * Изменение цен на автомате
   *
   * @param machineId id  автомата
   * @param prices цены и объёмы автомата
   */
  editMachinePrices(machineId: number, prices: EditMachinePricesDTO) {
    return this.request.post<EditMachinePricesDTO, void>(
      `${machineControlBaseUrl}/price/change/${machineId}`,
      {
        ...prices,
        calibrations: prices.calibrations.map((item) => ({
          ...item,
          categoryConfigMachine: JSON.stringify(item.categoryConfigMachine) as any,
          purposeConfigMachine: JSON.stringify(item.purposeConfigMachine) as any,
        })),
      },
    );
  }

  /**
   * Изменение наполнения автомата
   *
   * @param machineId id автомата
   * @param storage обновлённый склад автомата + цены
   */
  editMachineStorage(machineId: number, storage: EditMachineStorageDTO) {
    return this.request.post<EditMachineStorageDTO, void>(
      `${machineControlBaseUrl}/machine-cell/edit/${machineId}`,
      {
        ...storage,
        calibrations: storage.calibrations.map((item) => ({
          ...item,
          categoryConfigMachine: JSON.stringify(item.categoryConfigMachine) as any,
          purposeConfigMachine: JSON.stringify(item.purposeConfigMachine) as any,
        })),
      },
    );
  }

  /**
   * Изменение базовой информации автомата
   *
   * @param machineId id автомата
   * @param baseInfo обновлённая базовая информация
   */
  editMachineBaseInfo(machineId: number, baseInfo: EditMachineBaseInfoDTO) {
    return this.request.post<EditMachineBaseInfoDTO, void>(
      `${machineControlBaseUrl}/machine/edit/${machineId}`,
      baseInfo,
    );
  }

  /**
   * Получение данных о статусах кастомных действий
   *
   * @param machineId id автомата
   */
  getMachineSettingsActionsStatus(machineId: number): Promise<MachineSettingsActionStatusDTO[]> {
    return this.request.get(`${machineControlBaseUrl}/settings/list/action-status/${machineId}`);
  }

  /**
   * Настройки автомата
   *
   * @param machineId id автомата
   */
  getMachineSettings(machineId: number): Promise<MachineSettingsRes> {
    return this.request.get(`${machineControlBaseUrl}/setting/element/${machineId}`);
  }

  /**
   * Отправка кастомных действий на автомат
   *
   * @param data данные для отправки
   */
  sendMachineCustomAction(data: SendMachineCustomActionDTO) {
    return this.request.post(`${machineControlBaseUrl}/setting/action`, data);
  }

  /**
   * Изменение ячейки воды
   *
   * @param data данные формы изменения настроек воды
   * @param machineId id автомата
   */
  editWaterCell(data: EditWaterCell, machineId: number) {
    return this.request.post(
      `${machineControlBaseUrl}/machine-cell/edit-cell-water/${machineId}`,
      data,
    );
  }

  /**
   * Перегенерация ключа регистрации автомата
   *
   * @param keyCode старый ключ регистрации автомата
   */
  regenerateMachineRegistrationCode(
    keyCode: string,
  ): Promise<RegenerateMachineRegistrationCodeRes> {
    return this.request.get(`${machineControlBaseUrl}/registration-code/generate/${keyCode}`);
  }

  /**
   * Переключение активности ключа регистрации автомата
   *
   * @param keyCode код регистрации автомата
   */
  toggleActiveMachineRegistrationCode(keyCode: string) {
    return this.request.post(`${machineControlBaseUrl}/registration-code/toggle-active/${keyCode}`);
  }

  /**
   * Получение или генерация ключа регистрации автомата
   *
   * @param organizationId id организации
   */
  getMachineRegistrationCode(organizationId: number): Promise<MachineRegistrationCodeDTO> {
    return this.request.post(
      `${machineControlBaseUrl}/registration-code/create-or-get/${organizationId}`,
    );
  }

  /**
   * Получение калибровки ингредиента
   *
   * @param machineId id автомата
   * @param ingredientId id ингредиента
   */
  getIngredientCalibrationValue(
    machineId: number,
    ingredientId: number,
  ): Promise<MachineIngredientCalibrationValueRes> {
    return this.request.get(
      `${machineControlBaseUrl}/calibration/element/${machineId}/${ingredientId}`,
    );
  }

  /**
   * Получение списка автоматов
   *
   * @param filters фильтры списка автоматов
   */
  getMachineListWithFilters(filters: MachineFiltersDTO): Promise<MachineListDTO> {
    return this.request.get(
      `${machineControlBaseUrl}/machine/list-with-filters/${getMachineListParameters(filters)}`,
    );
  }

  /**
   * Отключение автомата от телеметрии
   *
   * @param machineId id автомата
   * @param shutdownReason причина отключения автомата
   * @param isDropStock сбросить остатки автомата
   */
  disconnectMachine(
    machineId: number,
    shutdownReason: ShutdownReasonFilters,
    isDropStock: boolean,
  ) {
    return this.request.delete(
      `${machineControlBaseUrl}/machine/shutdown/${getMachineDisconnectParameters({ machineId, shutdownReason, isDropStock })}`,
    );
  }

  /**
   * Удаление торговой точки
   *
   * @param outletId id торговой точки
   */
  deleteOutlet(outletId: number) {
    return this.request.delete(`${machineControlBaseUrl}/outlet/${outletId}`);
  }

  /**
   * Получение списка торговых точек
   *
   * @param organizationId id организации
   * @param filters фильтра и сортировки списка торговых точек
   */
  getOutletList(organizationId: number, filters: OutletFiltersDTO): Promise<OutletListDTO> {
    // return getDataFromServer(outletList);
    return this.request.get(
      `${machineControlBaseUrl}/outlet/list/${getOutletListParameters(organizationId, { ...filters, isArchived: false })}`,
    );
  }

  /**
   * Получение фильтра торговой точки
   *
   * @param organizationId id организации
   */
  getOutletListFilters(organizationId: number): Promise<OutletFiltersInfoDTO> {
    return this.request.get(
      `${machineControlBaseUrl}/outlet/filter-dictionaries/${organizationId}`,
    );
  }

  /**
   * Изменение торговой точки
   *
   * @param data данные формы изменения торговой точки
   * @param outletId id торговой точки
   */
  editOutlet(data: EditOutletDTO, outletId: number) {
    return this.request.post(`${machineControlBaseUrl}/outlet/edit/${outletId}`, data);
  }

  /**
   * Создание торговой точки
   *
   * @param data данные формы создания торговой точки
   */
  createOutlet(data: CreateOutletDTO): Promise<{ id: number }> {
    return this.request.post(`${machineControlBaseUrl}/outlet/create`, data);
  }

  /**
   * Получение списка id автоматов торговой точки
   *
   * @param outletId id торговой точки
   */
  getOutletMachineIds(outletId: number): Promise<OutletMachineIdsDTO> {
    return this.request.get(`${machineControlBaseUrl}/outlet/machines-ids-list/${outletId}`);
  }
}
