import { createAsyncThunk } from '@reduxjs/toolkit';
import { CabinetDTO, EditUserDTO } from '../../types/serverInterface/cabinetDTO';
import { api } from '../../app/api';
import { DocumentLinkDTO, EditFileLinkUserDTO } from '../../types/serverInterface/documentLink';

/**
 * Получение личного кабинета пользователя
 */
export const getCabinetThunk = createAsyncThunk<CabinetDTO>('getCabinet', async () => {
  return await api.user.fetchCabinet();
});

/**
 * Изменение пользователя
 *
 * @param data данные формы изменения пользователя
 */
export const editUserThunk = createAsyncThunk<void, EditUserDTO>('editUser', async (data) => {
  return await api.user.editUser(data);
});

/**
 * Получение списка файлов пользователя
 */
export const getUserDocumentLinksThunk = createAsyncThunk<DocumentLinkDTO[], string>(
  'getUserDocumentLinks',
  async (userUuid: string) => {
    return await api.user.fetchUserDocs(userUuid);
  },
);

/**
 * Изменение списка файлов пользователя
 */
export const editFileLinksUserThunk = createAsyncThunk<void, EditFileLinkUserDTO>(
  'editFileLinksUser',
  async (data) => {
    return await api.user.updateFileLinksUser(data);
  },
);
