import { createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../app/api';
import {
  CreateMachineDTO,
  EditMachineBaseInfoDTO,
  EditMachineDTO,
  EditMachinePricesDTO,
  EditMachineStorageDTO,
  EditWaterCell,
  MachineBaseInfoDTO,
  MachineHomeRes,
  MachineSmallListItemDTO,
  MachinePricesInfoRes,
  MachineSalesRes,
  MachineSettingsActionStatusDTO,
  MachineSettingsRes,
  MachineStorageInfoRes,
  RefillCellDTO,
  RefillMachineStorageDTO,
  SendMachineCustomActionDTO,
  MachineLogListDTO,
} from '../../types/serverInterface/machineDTO';
import {
  MachineModelDetailsDTO,
  MachineModelDTO,
  CreateMachineModelDTO,
} from '../../types/serverInterface/machineModelDTO';
import { PaginationQtyRes } from '../../types/types';
import {
  MachineRegistrationCodeDTO,
  RegenerateMachineRegistrationCodeRes,
} from '../../types/serverInterface/MachineRegistrationCodeDTO';
import { MachineIngredientCalibrationValueRes } from '../../types/serverInterface/machineIngredientCalibrationValueDTO';
import {
  MachineFiltersDTO,
  MachineListDTO,
  ShutdownReasonFilters,
} from '../../types/serverInterface/machineListDTO';
import {
  CreateOutletDTO,
  EditOutletDTO,
  OutletFiltersDTO,
  OutletFiltersInfoDTO,
  OutletListDTO,
  OutletMachineIdsDTO,
} from '../../types/serverInterface/outletDTO';
import {
  MachineSaleInfoLastDateDTO,
  MachineSaleInfoQtyDTO,
} from '../../types/serverInterface/MachineSaleInfoDTO';

/**
 * Получение списка моделей автомата
 */
export const getMachineModelListThunk = createAsyncThunk<MachineModelDTO[]>(
  'getMachineModelList',
  async () => {
    return await api.machineControl.fetchMachineModelList();
  },
);

/**
 * Получение детальной информации об автомате
 *
 * @param modelId {number} id модели автомата
 */
export const getMachineModelDetailsThunk = createAsyncThunk<MachineModelDetailsDTO, number>(
  'getMachineModelDetails',
  async (modelId) => {
    return await api.machineControl.fetchMachineModelDetails(modelId);
  },
);

/**
 * Создание модели автомата
 *
 * @param {CreateMachineModelDTO} data данные формы создания модели автомата
 */
export const createMachineModelThunk = createAsyncThunk<{ id: number }, CreateMachineModelDTO>(
  'createMachineModel',
  async (data) => {
    return await api.machineControl.createMachineModel(data);
  },
);

/**
 * Удаление модели автомата
 *
 * @param {number} modelId id модели автомата
 */
export const deleteMachineModelThunk = createAsyncThunk<void, number>(
  'deleteMachineModel',
  async (modelId) => {
    return await api.machineControl.deleteMachineModel(modelId);
  },
);

/**
 * Удаление автомата
 *
 * @param machineId id автомата
 */
export const deleteMachineThunk = createAsyncThunk<void, number>(
  'deleteMachine',
  async (machineId) => {
    return await api.machineControl.deleteMachine(machineId);
  },
);

/**
 * Получения списка автоматов организации
 *
 * @param organizationId id организации
 */
export const getMachineListByOrganizationIdThunk = createAsyncThunk<
  MachineSmallListItemDTO[],
  number
>('getMachineListByOrganizationId', async (organizationId) => {
  return await api.machineControl.fetchMachineListByOrganizationId(organizationId);
});

/**
 * Пополнение остатков автомата
 *
 * @param storage обновленные остатки склада автомата
 */
export const refillMachineThunk = createAsyncThunk<void, RefillCellDTO[]>(
  'refillMachine',
  async (storage) => {
    return await api.machineControl.refillMachine(storage);
  },
);

/**
 * Изменение автоматаа
 *
 * @param data данные формы изменения автомата
 * @param machineId id автомата
 */
export const editMachineThunk = createAsyncThunk<void, { data: EditMachineDTO; machineId: number }>(
  'editMachine',
  async ({ data, machineId }) => {
    return await api.machineControl.editMachine(data, machineId);
  },
);

/**
 * Создание автомата
 *
 * @param data данные формы создания автомата
 */
export const createMachineThunk = createAsyncThunk<{ id: number }, CreateMachineDTO>(
  'createMachine',
  async (data) => {
    return await api.machineControl.createMachine(data);
  },
);

/**
 * Получение базовой информации о автомате
 *
 * @param machineId id автомата
 */
export const getMachineBaseInfoThunk = createAsyncThunk<MachineBaseInfoDTO, number>(
  'getMachineBaseInfo',
  async (machineId) => {
    return await api.machineControl.fetchMachineBaseInfo(machineId);
  },
);

/**
 * Получение информации о автомате для домашней страницы
 *
 * @param machineId id автомата
 */
export const getMachineHomePageInfoThunk = createAsyncThunk<MachineHomeRes, number>(
  'getMachineHomePageInfo',
  async (machineId) => {
    return await api.machineControl.fetchMachineHomePageInfo(machineId);
  },
);

/**
 * Получение списка логов автомата
 *
 * @param machineId id автомата
 */
export const getMachineLogListThunk = createAsyncThunk<MachineLogListDTO, number>(
  'getMachineLogList',
  async (machineId) => {
    return await api.machineControl.getMachineLogList(machineId);
  },
);

/**
 * Получение информации о складе автомата
 *
 * @param machineId id автомата
 */
export const getMachineStorageInfoThunk = createAsyncThunk<MachineStorageInfoRes, number>(
  'getMachineStorageInfo',
  async (machineId) => {
    return await api.machineControl.fetchMachineStorageInfo(machineId);
  },
);

/**
 * Получение информации о ценах автомата
 *
 * @param machineId id автомата
 */
export const getMachinePricesInfoThunk = createAsyncThunk<MachinePricesInfoRes, number>(
  'getMachinePricesInfo',
  async (machineId) => {
    return await api.machineControl.fetchMachinePricesInfo(machineId);
  },
);

/**
 * Пополнение остатков автомата
 *
 * @param refillStorage пополненый склад
 * @param machineId id  автомата
 */
export const refillMachineStorageThunk = createAsyncThunk<
  void,
  { refillStorage: RefillMachineStorageDTO; machineId: number }
>('refillMachineStorage', async ({ refillStorage, machineId }) => {
  return await api.machineControl.refillMachineStorage(refillStorage, machineId);
});

/**
 * Изменение цен на автомате
 *
 * @param machineId id  автомата
 * @param prices цены и объёмы автомата
 */
export const editMachinePricesThunk = createAsyncThunk<
  void,
  { machineId: number; prices: EditMachinePricesDTO }
>('editMachinePrices', async ({ machineId, prices }) => {
  return await api.machineControl.editMachinePrices(machineId, prices);
});

/**
 * Изменение наполнения автомата
 *
 * @param machineId id автомата
 * @param storage обновлённый склад автомата + цены
 */
export const editMachineStorageThunk = createAsyncThunk<
  void,
  { machineId: number; storage: EditMachineStorageDTO }
>('editMachineStorage', async ({ machineId, storage }) => {
  return await api.machineControl.editMachineStorage(machineId, storage);
});

/**
 * Изменение базовой информации автомата
 *
 * @param machineId id автомата
 * @param baseInfo обновлённая базовая информация
 */
export const editMachineBaseInfoThunk = createAsyncThunk<
  void,
  {
    machineId: number;
    baseInfo: EditMachineBaseInfoDTO;
  }
>('editMachineBaseInfo', async ({ machineId, baseInfo }) => {
  return await api.machineControl.editMachineBaseInfo(machineId, baseInfo);
});

/**
 * Список продаж автомата
 *
 * @param serialNumber серийный номер
 * @param page страница
 * @param limit элементов наа странице
 */
export const getMachineSaLesListThunk = createAsyncThunk<
  MachineSalesRes,
  { serialNumber: string; page: number; limit: number }
>('getMachineSaLesList', async ({ serialNumber, page, limit }) => {
  return await api.promoCode.getMachineSaLesList(serialNumber, page, limit);
});

/**
 * Список продаж автомата для печати
 *
 * @param serialNumber серийный номер
 * @param page страница
 * @param limit элементов наа странице
 */
export const getMachineSaLesListByPrintThunk = createAsyncThunk<
  MachineSalesRes,
  { serialNumber: string; page: number; limit: number }
>('getMachineSaLesListByPrint', async ({ serialNumber, page, limit }) => {
  return await api.promoCode.getMachineSaLesList(serialNumber, page, limit);
});

/**
 * Количество элементов продаж
 *
 * @param serialNumber серийный номер автомата
 */
export const getMachineSaLesListQtyThunk = createAsyncThunk<PaginationQtyRes, string>(
  'getMachineSaLesListQty',
  async (serialNumber) => {
    return await api.promoCode.getMachineSaLesListQty(serialNumber);
  },
);

/**
 * Получение данных о статусах кастомных действий
 *
 * @param machineId id автомата
 */
export const getMachineSettingsActionsStatusThunk = createAsyncThunk<
  MachineSettingsActionStatusDTO[],
  number
>('getMachineSettingsActionsStatus', async (machineId) => {
  return await api.machineControl.getMachineSettingsActionsStatus(machineId);
});

/**
 * Настройки автомата
 *
 * @param machineId id автомата
 */
export const getMachineSettingsThunk = createAsyncThunk<MachineSettingsRes, number>(
  'getMachineSettings',
  async (machineId) => {
    return await api.machineControl.getMachineSettings(machineId);
  },
);

/**
 * Отправка кастомных действий на автомат
 *
 * @param data данные для отправки
 */
export const sendMachineCustomActionThunk = createAsyncThunk<void, SendMachineCustomActionDTO>(
  'sendMachineCustomAction',
  async (data) => {
    return await api.machineControl.sendMachineCustomAction(data);
  },
);

/**
 * Изменение ячейки воды
 *
 * @param data данные формы изменения настроек воды
 * @param machineId id автомата
 */
export const editWaterCellThunk = createAsyncThunk<
  void,
  { data: EditWaterCell; machineId: number }
>('editWaterCell', async ({ data, machineId }) => {
  return await api.machineControl.editWaterCell(data, machineId);
});

/**
 * Перегенерация ключа регистрации автомата
 *
 * @param keyCode старый ключ регистрации автомата
 */
export const regenerateMachineRegistrationCodeThunk = createAsyncThunk<
  RegenerateMachineRegistrationCodeRes,
  string
>('regenerateMachineRegistrationCode', async (keyCode: string) => {
  return await api.machineControl.regenerateMachineRegistrationCode(keyCode);
});

/**
 * Переключение активности ключа регистрации автомата
 *
 * @param keyCode код регистрации автомата
 */
export const toggleActiveMachineRegistrationCodeThunk = createAsyncThunk<void, string>(
  'toggleActiveMachineRegistrationCode',
  async (keyCode) => {
    return await api.machineControl.toggleActiveMachineRegistrationCode(keyCode);
  },
);

/**
 * Получение или генерация ключа регистрации автомата
 *
 * @param organizationId id организации
 */
export const getMachineRegistrationCodeThunk = createAsyncThunk<MachineRegistrationCodeDTO, number>(
  'getMachineRegistrationCode',
  async (organizationId: number) => {
    return await api.machineControl.getMachineRegistrationCode(organizationId);
  },
);

/**
 * Получение калибровки ингредиента
 *
 * @param machineId id автомата
 * @param ingredientId id ингредиента
 * @param cellCategoryName название категории контейнера
 */
export const getIngredientCalibrationValueThunk = createAsyncThunk<
  MachineIngredientCalibrationValueRes,
  { machineId: number; ingredientId: number; cellCategoryName: string }
>('getIngredientCalibrationValue', async ({ machineId, ingredientId }) => {
  return await api.machineControl.getIngredientCalibrationValue(machineId, ingredientId);
  // .then((res) => ({
  //   ...res,
  //   categoryConfigMachine: res.categoryConfigMachine,
  //   purposeConfigMachine: res.purposeConfigMachine,
  // }));
});

/**
 * Получение списка автоматов
 *
 * @param filters фильтры списка автоматов
 */
export const getMachineListWithFiltersThunk = createAsyncThunk<MachineListDTO, MachineFiltersDTO>(
  'getMachineListWithFilters',
  async (filters) => {
    return await api.machineControl.getMachineListWithFilters(filters);
  },
);

/**
 * Отключение автомата от телеметрии
 *
 * @param machineId id автомата
 * @param shutdownReason причина отключения автомата
 * @param isDropStock сбросить остатки автомата
 */
export const disconnectMachineThunk = createAsyncThunk<
  void,
  { machineId: number; shutdownReason: ShutdownReasonFilters; isDropStock: boolean }
>('disconnectMachine', async ({ machineId, shutdownReason, isDropStock }) => {
  return await api.machineControl.disconnectMachine(machineId, shutdownReason, isDropStock);
});

/**
 * Удаление торговой точки
 *
 * @param outletId id торговой точки
 */
export const deleteOutletThunk = createAsyncThunk<void, number>(
  'deleteOutlet',
  async (outletId) => {
    return await api.machineControl.deleteOutlet(outletId);
  },
);

/**
 * Получение списка торговых точек
 *
 * @param organizationId id организации
 * @param filters фильтра списка торговых точек
 * @param sorted сортировки списка торговых точек
 */
export const getOutletListThunk = createAsyncThunk<
  OutletListDTO,
  { organizationId: number; filters: OutletFiltersDTO }
>('getOutletList', async ({ organizationId, filters }) => {
  return await api.machineControl.getOutletList(organizationId, filters);
});

/**
 * Получение фильтра торговой точки
 *
 * @param organizationId id организации
 */
export const getOutletListFiltersThunk = createAsyncThunk<OutletFiltersInfoDTO, number>(
  'getOutletListFilters',
  async (organizationId) => {
    return await api.machineControl.getOutletListFilters(organizationId);
  },
);

/**+
 * Изменение торговой точки
 *
 * @param data данные формы изменения торговой точки
 * @param outletId id торговой точки
 */
export const editOutletThunk = createAsyncThunk<void, { data: EditOutletDTO; outletId: number }>(
  'editOutlet',
  async ({ data, outletId }) => {
    return await api.machineControl.editOutlet(data, outletId);
  },
);

/**
 * Создание торговой точки
 *
 * @param data данные формы создания торговой точки
 */
export const createOutletThunk = createAsyncThunk<{ id: number }, CreateOutletDTO>(
  'createOutlet',
  async (data) => {
    return await api.machineControl.createOutlet(data);
  },
);

/**
 * Получение списка id автоматов торговой точки
 *
 * @param outletId id торговой точки
 */
export const getOutletMachineIdsThunk = createAsyncThunk<OutletMachineIdsDTO, number>(
  'getOutletMachineIds',
  async (outletId) => {
    return await api.machineControl.getOutletMachineIds(outletId);
  },
);

/**
 * Получение даты последней продажи автомата
 *
 * @param serialNumber серийный номер автомата
 */
export const getLastSaleDateByMachineSerialNumberThunk = createAsyncThunk<
  MachineSaleInfoLastDateDTO,
  string
>('getLastSaleDateByMachineSerialNumber', async (serialNumber) => {
  return await api.promoCode.getLastSaleDateByMachineSerialNumber(serialNumber);
});

/**
 * Получение количества продаж за различные периоды
 *
 * @param serialNumber серийный номер
 */
export const getSalesQtyByMachineSerialNumberThunk = createAsyncThunk<
  MachineSaleInfoQtyDTO,
  string
>('getSalesQtyByMachineSerialNumber', async (serialNumber) => {
  return await api.promoCode.getSalesQtyByMachineSerialNumber(serialNumber);
});
